/**
 * Check if timestamp has expired
 * @param {object} options Related options
 * @param {number} options.timestamp Timestamp to check
 * @param {number} options.days Expiration in number of days
 * @returns {boolean} Timestamp has expired or not
 */
export default function isTimestampExpired({ timestamp, days }) {
	const daysInMilliseconds = 24 * 60 * 60 * 1000 * days
	return new Date().getTime() - timestamp >= daysInMilliseconds
}
