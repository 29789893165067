import 'lazysizes'

export default class Footer {
	constructor() {
		this.container = document.querySelector('.footer')
		this.manageCookiesButton = this.container.querySelector('.cookies')
		this.newsletterButton = this.container.querySelector('.footer-newsletter')

		this.onNewsletterButtonClick.bind(this)
		this.onManageCookieButtonClick.bind(this)
	}

	init() {
		this.addEvents()
	}

	addEvents() {
		this.manageCookiesButton.addEventListener('click', this.onManageCookieButtonClick)
		this.newsletterButton.addEventListener('click', this.onNewsletterButtonClick)
	}

	onManageCookieButtonClick() {
		document.addEventListener('orejimeReady', () => {
			window.orejime.show()
		})
		document.dispatchEvent(new CustomEvent('loadCmp'))
	}

	onNewsletterButtonClick() {
		if (!document.querySelector('.modalNewsletter')) {
			document.dispatchEvent(new CustomEvent('newsletterModal'))
		} else {
			document.dispatchEvent(new CustomEvent('show-newsletter-modal'))
		}
	}
}
