import countdownTemplate from '../scripts/template/countdown-template.js'
import WiderLink from 'shared/widerLink/WiderLink'

export default class Countdown {
	constructor({ element, deadline, dataWideTarget = null, stopCallback = null }) {
		this.element = element
		this.deadline = new Date(deadline).getTime()
		this.stopCallback = stopCallback || null
		this.container = countdownTemplate()
		this.days = this.container.querySelector('.countdown-days')
		this.hours = this.container.querySelector('.countdown-hours')
		this.minutes = this.container.querySelector('.countdown-minutes')
		this.seconds = this.container.querySelector('.countdown-seconds')
		this.dataWideTarget = dataWideTarget
	}

	init() {
		if (this.deadline < Date.now()) {
			this.element.appendChild(this.container)
			this.container.replaceChildren(countdownTemplate({ expired: true }))
		} else {
			this.element.appendChild(this.container)
			this.count()

			if (this.dataWideTarget) {
				this.widerLink = new WiderLink({
					elementSelector: this.dataWideTarget
				})
				this.widerLink.init()
			}
		}
	}

	count() {
		// Update countdown timer
		const x = setInterval(() => {
			// Get current time
			const now = new Date().getTime()

			// Calculate difference
			const distance = this.deadline - now

			// Calculate days, hours, minutes et seconds
			const days = Math.floor(distance / (1000 * 60 * 60 * 24))
				.toString()
				.padStart(2, '0')
			const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
				.toString()
				.padStart(2, '0')
			const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
				.toString()
				.padStart(2, '0')
			const seconds = Math.floor((distance % (1000 * 60)) / 1000)
				.toString()
				.padStart(2, '0')

			// Display the result
			this.days.innerHTML = distance > 0 ? days : ''
			this.hours.innerHTML = distance > 0 ? hours : ''
			this.minutes.innerHTML = distance > 0 ? minutes : ''
			this.seconds.innerHTML = distance > 0 ? seconds : ''

			// If countdown is over
			if (distance < 0) {
				clearInterval(x)
				if (typeof this.stopCallback === 'function') {
					this.stopCallback()
				} else {
					this.container.replaceChildren(countdownTemplate({ expired: true }))
					if (this.dataWideTarget) {
						document.querySelector(this.dataWideTarget).style.pointerEvents = 'none'
					}
				}
			}
		}, 1000)
	}
}
