/**
 * Retrieves the value of a cookie by its name.
 * @param {string} cookieName - The name of the cookie to retrieve.
 * @returns {string|boolean} The value of the cookie if it exists, otherwise false.
 */
export function getCookie(cookieName) {
	if (doesCookieExist(cookieName)) {
		return document.cookie
			.split('; ')
			.find((row) => row.startsWith(`${cookieName}=`))
			.split('=')[1]
	} else {
		return false
	}
}

/**
 * Sets a cookie with the specified name, value, and expiration days.
 * @param {string} cookieName - The name of the cookie.
 * @param {string} cookieValue - The value of the cookie.
 * @param {number} expirationDays - The number of days until the cookie expires.
 */
export function setCookie(cookieName, cookieValue, expirationDays) {
	const d = new Date()
	d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000)
	const expires = `expires=${d.toUTCString()}`
	document.cookie = `${cookieName}=${cookieValue};${expires};path=/;SameSite=Strict`
}

/**
 * Checks if a cookie with the specified name exists.
 * @param {string} cookieName - The name of the cookie to check.
 * @returns {boolean} - Returns true if the cookie exists, false otherwise.
 */
export function doesCookieExist(cookieName) {
	return document.cookie.split('; ').some((row) => row.startsWith(`${cookieName}=`))
}
