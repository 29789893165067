import { getCookie } from 'shared/utils/cookie.js'
import isTimestampExpired from 'shared/utils/is-timestamp-expired.js'

/**
 *
 */
export default function DynamicImportModalNewsletter() {
	const storageKey = 'FFU-consent'

	const modalNewsletterStorage = JSON.parse(window.localStorage.getItem('ffu-modalNewsletter'))

	const timestampExpired = isTimestampExpired({
		timestamp: modalNewsletterStorage?.timestamp,
		days: 5
	})

	const dontShow = modalNewsletterStorage?.dontShow || false
	const isTimeStampValid = !modalNewsletterStorage || timestampExpired
	const canBeDisplayed = !!getCookie(storageKey)

	const importModalNewsletter = async () => {
		import(
			/* webpackChunkName: "dynamic-import-newsletter-modal" */ 'shared/modals/components/modal-newsletter/config.js'
		)
	}

	if (canBeDisplayed && isTimeStampValid && !dontShow) {
		importModalNewsletter()
	}

	document.addEventListener('newsletterModal', () => {
		importModalNewsletter()
	})
}
