import Countdown from 'shared/countdown/assets/scripts/countdown.js'

export default class Hero {
	constructor() {
		this.container = document.querySelector('.hero')
		this.eventData = JSON.parse(this.container.getAttribute('data-event'))
		this.heroCountdown = this.container.querySelector('.hero-countdown')

		this.countdown = new Countdown({
			element: this.heroCountdown,
			dataWideTarget: '.hero',
			deadline: this.eventData.endDate
		})
	}

	init() {
		this.heroCountdown && this.countdown.init()
	}
}
