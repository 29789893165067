export default class TopBar {
	/**
	 * Constructs a new instance of TopBar.
	 */
	constructor() {
		this.container = document.querySelector('.topBar')
		this.subscribeButton = this.container.querySelector('.topBar-newsletter')
	}

	init() {
		this.addEvents()
	}

	addEvents() {
		this.subscribeButton.addEventListener('click', this.onClick.bind(this))
	}

	onClick() {
		if (!document.querySelector('.modalNewsletter')) {
			document.dispatchEvent(new CustomEvent('newsletterModal'))
		} else {
			document.dispatchEvent(new CustomEvent('show-newsletter-modal'))
		}
	}
}
