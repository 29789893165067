import Cta from 'shared/macros/assets/scripts/cta'

/**
 * Renders a countdown or an expired message based on the `expired` flag.
 * @param {object} options - The options for the function.
 * @param {boolean} [options.expired] - Indicates whether the event has expired - default set to false.
 * @returns {HTMLElement} A JSX element displaying either the countdown or an expired message.
 */
export default function ({ expired } = {}) {
	const url = new URL(window.location.href)
	const link = `${url.protocol}//${url.hostname}/open-call/the-universal-language-exhibition`

	return (
		<div className={`countdown${expired === true ? ' expired' : ''}`}>
			{!expired ? (
				<>
					<div className="countdown-numbers">
						<div className="digits countdown-days" data-type="days"></div>
						<div className="digits countdown-hours" data-type="hours"></div>
						<div className="digits countdown-minutes" data-type="minutes"></div>
						<div className="digits countdown-seconds" data-type="seconds"></div>
					</div>
					<div>
						{Cta({
							tag: 'a',
							type: 'main',
							content: 'Participate',
							attributes: {
								class: 'black countdown-link',
								href: link,
								'data-wide': ''
							}
						})}
					</div>
				</>
			) : (
				<div className="countdown-expired">
					Event closed. Thank you for your participation!
				</div>
			)}
		</div>
	)
}
