import { getCookie } from 'shared/utils/cookie.js'

/**
 * Importing the CMP (Consent Management Platform) script.
 */
export default function DynamicImportCmp() {
	const storageKey = 'FFU-consent'
	const canBeDisplayed = !getCookie(storageKey)

	const importCmp = async () => {
		import(/* webpackChunkName: "dynamic-import-cmp" */ 'shared/cmp/config.js').then(() => {
			document.dispatchEvent(new CustomEvent('orejimeReady'))
		})
	}

	if (canBeDisplayed) {
		importCmp()
	}

	document.addEventListener('loadCmp', () => {
		importCmp()
	})
}
