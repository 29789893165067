import loader from 'shared/loader/assets/scripts/templates/loader-template.js'

/**
 * Generate a cta with JSX
 * @param {object} options Element options
 * @param {('primary'|'secondary'|'tertiary')} options.type - Type of the cta
 * @param {('button'|'a')} options.tag - Type of the tag
 * @param {string} options.content - Content of the cta
 * @param {string} options.icon - Icon of the cta
 * @param {boolean} options.hasHover - Whether hover is enabled
 * @param {object} options.attributes - Attributes of the cta
 * @param {boolean} options.hasLoader - wether cta has loader or not
 * @returns {HTMLElement} JSX HTML Element
 */
export default function Cta({
	type,
	tag,
	content,
	icon,
	hasHover = true,
	hasLoader = false,
	attributes = {}
}) {
	const Customtag = `${tag}`
	const classAttribute = attributes.class || ''
	delete attributes.class // Prevent the class attribute to be duplicated

	return (
		<Customtag
			className={`cta ${type} ${classAttribute}${!content && !!icon ? ' iconOnly' : ''}${
				!hasHover ? ' noHover' : ''
			}`}
			{...attributes}
		>
			<span className="cta-content">{content}</span>
			{hasLoader && loader()}
		</Customtag>
	)
}
