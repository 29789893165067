/**
 * WiderLink
 * @module WiderLink
 */
export default class WiderLink {
	/**
	 * Instanciante the WiderLink module
	 * @param {object} options - An configuration object that can contain:
	 * @param {string} options.elementSelector - the CSS selector for which the module will instanciate
	 * @param {boolean} options.autoload - A boolean to tell wether the modules initiates itself from the constructor
	 * @param {string} options.source - the CSS selector to target the source nodes
	 * @param {string} options.target - the CSS selector to target target nodes
	 * @param {string} options.ignore - the CSS selector to target nodes that won't be widified
	 */
	constructor({
		elementSelector = 'body',
		autoload = false,
		source = '[data-wide]',
		target = '[data-wide-target]',
		ignore = '[data-wide-ignore]'
	} = {}) {
		this.settings = {
			elementSelector,
			autoload,
			source,
			target,
			ignore
		}

		this.autoload()
	}

	/**
	 * Autoload if autoload parameter is true
	 */
	autoload() {
		if (this.settings.autoload) {
			this.domReady(() => {
				this.init()
			})
		}
	}

	/**
	 * Sets the chosen or default element with the specified configuration
	 */
	setElement() {
		this.settings.elements = [...document.querySelectorAll(this.settings.elementSelector)]
	}

	/**
	 * Gets the array of links from the configured source selector
	 * @returns {Array} An array of HTMLElements
	 */
	getLinks() {
		this.setElement()
		const links = []
		this.settings.elements.forEach((element) => {
			links.push(...element.querySelectorAll(this.settings.source))
		})
		return links
	}

	/**
	 * Configure target for widden link
	 * @param {HTMLElement} target : A DOM element
	 * @param {string} link : An url
	 */
	configureTarget(target, link) {
		// If link hasn't been widified yet
		if (!target.link && target) {
			target.link = link // store related link on target node.
			target.onmouseup = this.targetsClickHandler.bind(this)
			target.onmouseover = this.targetsOverHandler.bind(this)
		}
	}

	/**
	 * Init WiderLink for the specified element or document if element is not defined
	 * @returns {NodeList} the widified links
	 */
	init() {
		const links = this.getLinks()

		// Iterate over all links
		for (const link of links) {
			// Gets the target where  to widify
			const target = this.getTarget(link)
			if (!target) {
				throw new Error(
					'[WiderLink for ' +
						this.settings.elementSelector +
						'] No target found for that link => ' +
						link
				)
			}

			// If the data-wide attribute is specified, a collecion of Node is returned
			if (target instanceof NodeList && target.length > 0) {
				for (const currentTarget of target) {
					this.configureTarget(currentTarget, link)
				}
			} else {
				this.configureTarget(target, link)
			}
		}

		return links
	}

	/**
	 * Fetch all links to widen in base element and extend them
	 * For semantic purposes
	 */
	reload() {
		this.init()
	}

	/**
	 * Gets the first ancestor element that matches a specified target or a data attribute selector.
	 * @param {HTMLElement} element - The starting element for traversal.
	 * @returns {NodeList|HTMLElement|boolean} - Returns a NodeList of elements matching the data attribute selector, a single HTMLElement if it matches the target, or false if no match is found.
	 */
	getTarget(element) {
		let baseElementReached = false
		if (element.matches(this.settings.elementSelector)) {
			baseElementReached = true
		}

		if (element.getAttribute('data-wide')) {
			return document.querySelectorAll(
				this.settings.target.replace(']', '="' + element.getAttribute('data-wide') + '"]')
			)
		} else {
			return element.matches(this.settings.target)
				? element
				: baseElementReached
				  ? false
				  : this.getTarget(element.parentNode)
		}
	}

	/**
	 * Simulates a cursor pointer over an extended link.
	 * @param {Event} event - The event object associated with the action triggering this handler.
	 */
	targetsOverHandler(event) {
		event.target.style.cursor = 'pointer'
	}

	/**
	 * Handles click events on elements contained within a base element.
	 * @param {Event} event - The click event object, containing information about the click action and the target element.
	 */
	targetsClickHandler(event) {
		for (let element = event.target; element.nodeName; element = element.parentElement) {
			// Stops getting parent when base element is reached
			if (element.matches(this.settings.ignore)) {
				break
			}
			if (element.matches(this.settings.target)) {
				// event.which === 3  => right click
				if (event.target.nodeName !== 'A') {
					this.definedTargetLink(event.currentTarget.link, event)
				}
				break
			} else if (element.matches(this.settings.elementSelector)) {
				break
			}
		}
	}

	/**
	 * Sets the target of the clicked link and opens it.
	 * @param {HTMLElement} element - The element on which to dispatch the new click event.
	 * @param {Event} event - The original event, whose properties (like button pressed, metaKey, ctrlKey) will be replicated in the new dispatched event.
	 */
	definedTargetLink(element, event) {
		element.dispatchEvent(
			new MouseEvent('click', {
				cancelable: true,
				button: event.button,
				metaKey: event.metaKey,
				ctrlKey: event.ctrlKey
			})
		)
	}

	/**
	 * Checks if the DOM is fully loaded and ready, and executes a callback function accordingly.
	 * @param {Function} callback - The callback function to execute when the DOM is ready.
	 */
	domReady(callback) {
		if (
			document.attachEvent
				? document.readyState === 'complete'
				: document.readyState !== 'loading'
		) {
			callback()
		} else {
			document.addEventListener('DOMContentLoaded', callback)
		}
	}
}
