// Import CSS
import 'shared/assets/styles/variables.css'
import 'shared/assets/styles/fonts.css'
import 'shared/assets/styles/reset.css'
import 'shared/assets/styles/base.css'
import 'shared/assets/styles/pictureGenerator.css'

// Import JS
import 'shared/top-bar/config.js'
// import 'shared/cmp/config'
import 'shared/back-to-top/config'
import 'shared/modals/config.js'
import DynamicImportCmp from './shared/cmp/scripts/dynamic-import.js'
import DynamicImportModalNewsletter from 'shared/modals/components/modal-newsletter/assets/scripts/dynamic-import.js'

DynamicImportModalNewsletter()
DynamicImportCmp()
