// eslint-disable-next-line @typescript-eslint/no-unused-vars
import lazySizes from 'lazysizes'
import Countdown from 'shared/countdown/assets/scripts/countdown.js'

export default class Sidebar {
	constructor() {
		this.container = document.querySelector('.sidebar')
		if (document.querySelector('.sidebar .hero.event')) {
			this.hero = this.container.querySelector('.hero')
			this.eventData = JSON.parse(this.hero.getAttribute('data-event'))
			this.sidebarCountdown = this.container.querySelector('.sidebar-eventCountdown')
			this.mql = window.matchMedia('(min-width: 1024px)')

			this.countdown = new Countdown({
				element: this.sidebarCountdown,
				deadline: this.eventData.endDate
			})
		}
		this.newsletterButton = this.container.querySelector('.sidebar-newsletter')
	}

	init() {
		if (this.sidebarCountdown && this.mql.matches) {
			this.countdown?.init()
		}

		this.addEvents()
	}

	addEvents() {
		this.newsletterButton.addEventListener('click', this.onClick.bind(this))
	}

	onClick() {
		if (!document.querySelector('.modalNewsletter')) {
			document.dispatchEvent(new CustomEvent('newsletterModal'))
		} else {
			document.dispatchEvent(new CustomEvent('show-newsletter-modal'))
		}
	}
}
