export default class Header {
	constructor() {
		this.header = document.querySelector('.header')

		this.headerWatchScroll = this.headerWatchScroll.bind(this)
	}

	init() {
		this.addEvents()
	}

	addEvents() {
		window.addEventListener('scroll', this.headerWatchScroll)
	}

	headerWatchScroll() {
		if (document.documentElement.scrollTop > 40) {
			if (!this.header.classList.contains('scrolling')) {
				this.header.classList.add('scrolling')
			}
		} else if (this.header.classList.contains('scrolling')) {
			this.header.classList.remove('scrolling')
		}
	}
}
